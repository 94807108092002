import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import {NgxPrintModule} from "ngx-print";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { ChartsModule } from "ng2-charts";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./layout/header/header.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { LoginComponent } from "./login/login.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatAutocompleteModule
} from "@angular/material";

import { IntlModule } from "@progress/kendo-angular-intl";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";

import { NgxEditorModule } from "ngx-editor";
import { TooltipModule } from "ngx-bootstrap/tooltip";

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from "@angular/material/core";

import { EditBarProfileComponent } from "./bar/edit-bar-profile/edit-bar-profile.component";
import { CategoryComponent } from "./menu/category/category.component";
import { AddMenuComponent } from "./menu/add-menu/add-menu.component";
import { ViewMenuComponent } from "./menu/view-menu/view-menu.component";
import { AddDiscountComponent } from "./discount/add-discount/add-discount.component";
import { ViewDiscountComponent } from "./discount/view-discount/view-discount.component";
import { AddHappyHourComponent } from "./happy-hour/add-happy-hour/add-happy-hour.component";
import { ViewHappyHourComponent } from "./happy-hour/view-happy-hour/view-happy-hour.component";
import { AddSpecialEventComponent } from "./special-event/add-special-event/add-special-event.component";
import { ViewSpecialEventComponent } from "./special-event/view-special-event/view-special-event.component";
import { BarProfileComponent } from "./bar/bar-profile/bar-profile.component";

import { ReportComponent } from "./report/report.component";
import { OrderHistoryComponent } from "./order/order-history/order-history.component";
import { CodeGenerateComponent } from "./code-generate/code-generate.component";
import { TopBarComponent } from "./top-bar/top-bar.component";
import { EventTicketComponent } from "./special-event/event-ticket/event-ticket.component";
import { AvailCodeDialogComponent } from "./avail-code-dialog/avail-code-dialog.component";
import { OfferItemsComponent } from "./offer-items/offer-items.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { InvoiceComponent } from "./order/invoice/invoice.component";
import { CreateKotComponent } from "./order/create-kot/create-kot.component";
import { KotListComponent } from "./order/kot-list/kot-list.component";
import { EditKotComponent } from "./order/edit-kot/edit-kot.component";
import { AddPatronComponent } from "./patron/add-patron/add-patron.component";
import { ViewPatronComponent } from "./patron/view-patron/view-patron.component";
import { AddBarComponent } from "./bar/add-bar/add-bar.component";
import { ViewBarComponent } from "./bar/view-bar/view-bar.component";
import { ViewGeoTaggedComponent } from "./geo-tagged/view-geo-tagged/view-geo-tagged.component";
import { AdminDashboardComponent } from "./admin-dashboard/admin-dashboard.component";
import { PatronHistoryComponent } from "./patron/patron-history/patron-history.component";
import { ViewSettlementComponent } from "./settlement/view-settlement/view-settlement.component";
import { SettingComponent } from "./setting/setting.component";
import { ChangePasswordComponent } from "./change-password/change-password.component";
import { PaymentComponent } from "./settlement/payment/payment.component";
import { PaymentHistoryComponent } from "./settlement/payment-history/payment-history.component";
import { BarsDialogComponent } from "./bars-dialog/bars-dialog.component";
import { AgmCoreModule } from "@agm/core";
import { PushNotificationsModule } from "ng-push";
import { NotificationComponent } from "./notification/notification.component";
import { NewsfeedComponent } from "./newsfeed/newsfeed.component";
import { BookingTableComponent } from "./booking-table/booking-table.component";
import { AcceptBookingDialogComponent } from "./accept-booking-dialog/accept-booking-dialog.component";
import { RevenueListComponent } from "./revenue-list/revenue-list.component";
import { ActivationRequestComponent } from "./admin/activation-request/activation-request.component";
import { AllCodeGenerateComponent } from "./admin/all-code-generate/all-code-generate.component";
import { AllDiscountOfferComponent } from "./admin/all-discount-offer/all-discount-offer.component";
import { AllHappyHoursComponent } from "./admin/all-happy-hours/all-happy-hours.component";
import { AllSpecialEventComponent } from "./admin/all-special-event/all-special-event.component";
import { AddStaffComponent } from "./staff/add-staff/add-staff.component";
import { ViewStaffComponent } from "./staff/view-staff/view-staff.component";
import { AddPriceComponent } from "./admin/event-price-setup/add-price/add-price.component";
import { ViewPriceComponent } from "./admin/event-price-setup/view-price/view-price.component";
import { AddTemplateComponent } from "./admin/templates/add-template/add-template.component";
import { BannertempletComponent} from "./admin/bannertemplet/bannertemplet.component";
import { TransactionComponent } from "./admin/transaction/transaction.component";
import { BarTransactionsComponent } from "./bar-transactions/bar-transactions.component";
import { AreasComponent } from "./admin/areas/areas.component";
import { AllOrdersComponent } from "./admin/all-orders/all-orders.component";
import { NewsfeedReportersComponent } from "./newsfeed-reporters/newsfeed-reporters.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { AllTableBookingsComponent } from "./admin/all-table-bookings/all-table-bookings.component";
import { TipHistoryComponent } from "./order/tip-history/tip-history.component";
import { PosComponent } from "./pos/pos.component";
import { GiftInvoiceComponent } from "./order/gift-invoice/gift-invoice.component";
import { EntireSalesReportComponent } from "./reports/entire-sales-report/entire-sales-report.component";
import { ItemSalesReportComponent } from "./reports/item-sales-report/item-sales-report.component";
import { DiscountSalesReportComponent } from "./reports/discount-sales-report/discount-sales-report.component";
import { CancelCodesReportComponent } from "./reports/cancel-codes-report/cancel-codes-report.component";
import { PreOrdersReportComponent } from "./reports/pre-orders-report/pre-orders-report.component";
import { MaxSellingItemsReportComponent } from './reports/max-selling-items-report/max-selling-items-report.component';
import { CommissionReportComponent } from './reports/commission-report/commission-report.component';
import { RestaurantOnboardingComponent } from './restaurant-onboarding/restaurant-onboarding.component';
import { BroadcastComponent } from './admin/broadcast/broadcast.component';
import { RestaurantsListComponent } from './admin/restaurants-list/restaurants-list.component';
import { UploadMenuComponent } from './menu/upload-menu/upload-menu.component';
import { AdminAppVersionComponent } from './admin/admin-app-version/admin-app-version.component';
import { BarUserComponent } from './bar/bar-user/bar-user.component';
import { BarUserListComponent } from './bar/bar-user-list/bar-user-list.component';
import { AdminPaymentBarComponent } from './admin/admin-payment-bar/admin-payment-bar.component';
import { PaymentBarTransactionComponent } from './admin/payment-bar-transaction/payment-bar-transaction.component';
import { NonSolUserListComponent } from './admin/non-sol-user-list/non-sol-user-list.component';
import { BarNonSolUserListComponent } from './bar/bar-non-sol-user-list/bar-non-sol-user-list.component';
import { BarZoneComponent } from './bar/bar-zone/bar-zone.component';
import { BarTableComponent } from './bar/bar-table/bar-table.component';
import { AddBarTableComponent } from './bar/add-bar-table/add-bar-table.component';
import { AddBarZoneComponent } from './bar/add-bar-zone/add-bar-zone.component';
import { WaiterAssignTableListComponent } from './bar/waiter-assign-table-list/waiter-assign-table-list.component';
import { WaiterAssignTableComponent } from './bar/waiter-assign-table/waiter-assign-table.component';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { AdminWalkingCustomerComponent } from './bar/admin-walking-customer/admin-walking-customer.component';
import { BarWalkingCustomerComponent } from './bar/bar-walking-customer/bar-walking-customer.component';
import { BarWalkingSameNumberCustomerComponent } from './bar/bar-walking-same-number-customer/bar-walking-same-number-customer.component';
import { AdminWalkingSameNumberCustomerComponent } from './admin/admin-walking-same-number-customer/admin-walking-same-number-customer.component';
import { EventManagerComponent } from './admin/event-manager/event-manager.component';
import { EventManagerCreateComponent } from './admin/event-manager-create/event-manager-create.component';
import { RestaurantOnboardingNewComponent } from './restaurant-onboarding-new/restaurant-onboarding-new.component';
import { MesterRestaurantComponent } from './admin/mester-restaurant/mester-restaurant.component';
import { AdmineventManagerEventsComponent } from './adminevent-manager-events/adminevent-manager-events.component';
import { EventManagerEventsComponent } from './admin/event-manager-events/event-manager-events.component';
import { EventManagerEventsTicketComponent } from './admin/event-manager-events-ticket/event-manager-events-ticket.component';
import { AllCodeGenerateRestaurantListComponent } from './admin/all-code-generate-restaurant-list/all-code-generate-restaurant-list.component';
import { AllDiscountOfferRestaurantListComponent } from './admin/all-discount-offer-restaurant-list/all-discount-offer-restaurant-list.component';
import { AllHappyHoursRestaurantListComponent } from './admin/all-happy-hours-restaurant-list/all-happy-hours-restaurant-list.component';
import { AllOrdersRestaurantListComponent } from './admin/all-orders-restaurant-list/all-orders-restaurant-list.component';
import { AllSpecialEventRestaurantListComponent } from './admin/all-special-event-restaurant-list/all-special-event-restaurant-list.component';
import { AllTableBookingRestaurantListComponent } from './admin/all-table-booking-restaurant-list/all-table-booking-restaurant-list.component';
import { AdminViewDiscountOfferComponent } from './admin/admin-view-discount-offer/admin-view-discount-offer.component';
import { AdminViewHappyHoursComponent } from './admin/admin-view-happy-hours/admin-view-happy-hours.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    DashboardComponent,
    LoginComponent,
    EditBarProfileComponent,
    CategoryComponent,
    AddMenuComponent,
    ViewMenuComponent,
    AddDiscountComponent,
    ViewDiscountComponent,
    AddHappyHourComponent,
    ViewHappyHourComponent,
    AddSpecialEventComponent,
    ViewSpecialEventComponent,
    BarProfileComponent,
    ReportComponent,
    OrderHistoryComponent,
    CodeGenerateComponent,
    TopBarComponent,
    EventTicketComponent,
    AvailCodeDialogComponent,
    OfferItemsComponent,
    ConfirmDialogComponent,
    InvoiceComponent,
    CreateKotComponent,
    KotListComponent,
    EditKotComponent,
    AddPatronComponent,
    ViewPatronComponent,
    AddBarComponent,
    ViewBarComponent,
    ViewGeoTaggedComponent,
    AdminDashboardComponent,
    PatronHistoryComponent,
    ViewSettlementComponent,
    SettingComponent,
    ChangePasswordComponent,
    PaymentComponent,
    PaymentHistoryComponent,
    BarsDialogComponent,
    NotificationComponent,
    NewsfeedComponent,
    BookingTableComponent,
    AcceptBookingDialogComponent,
    RevenueListComponent,
    ActivationRequestComponent,
    AllCodeGenerateComponent,
    AllDiscountOfferComponent,
    AllHappyHoursComponent,
    AllSpecialEventComponent,
    AddStaffComponent,
    ViewStaffComponent,
    AddPriceComponent,
    ViewPriceComponent,
    AddTemplateComponent,
    BannertempletComponent,
    TransactionComponent,
    BarTransactionsComponent,
    AreasComponent,
    AllOrdersComponent,
    NewsfeedReportersComponent,
    AllTableBookingsComponent,
    TipHistoryComponent,
    PosComponent,
    GiftInvoiceComponent,
    EntireSalesReportComponent,
    ItemSalesReportComponent,
    DiscountSalesReportComponent,
    CancelCodesReportComponent,
    PreOrdersReportComponent,
    MaxSellingItemsReportComponent,
    CommissionReportComponent,
    RestaurantOnboardingComponent,
    BroadcastComponent,
    RestaurantsListComponent,
    UploadMenuComponent,
    AdminAppVersionComponent,
    BarUserComponent,
    BarUserListComponent,
    AdminPaymentBarComponent,
    PaymentBarTransactionComponent,
    NonSolUserListComponent,
    BarNonSolUserListComponent,
    BarZoneComponent,
    BarTableComponent,
    AddBarTableComponent,
    AddBarZoneComponent,
    WaiterAssignTableListComponent,
    WaiterAssignTableComponent,
    AdminWalkingCustomerComponent,
    BarWalkingCustomerComponent,
    BarWalkingSameNumberCustomerComponent,
    AdminWalkingSameNumberCustomerComponent,
    EventManagerComponent,
    EventManagerCreateComponent,
    RestaurantOnboardingNewComponent,
    MesterRestaurantComponent,
    AdmineventManagerEventsComponent,
    EventManagerEventsComponent,
    EventManagerEventsTicketComponent,
    AllCodeGenerateRestaurantListComponent,
    AllDiscountOfferRestaurantListComponent,
    AllHappyHoursRestaurantListComponent,
    AllOrdersRestaurantListComponent,
    AllSpecialEventRestaurantListComponent,
    AllTableBookingRestaurantListComponent,
    AdminViewDiscountOfferComponent,
    AdminViewHappyHoursComponent
  ],
  entryComponents: [
    AvailCodeDialogComponent,
    ConfirmDialogComponent,
    BarsDialogComponent,
    AcceptBookingDialogComponent
  ],
  imports: [
    BrowserModule,
    NgxPrintModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ChartsModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    NgxEditorModule,
    TooltipModule,
    NgxMaterialTimepickerModule,
    ImageCropperModule,
    AgmCoreModule.forRoot({
      // apiKey: "AIzaSyAiJlRv6H0jkz6Q_MLgwvBMYP35K4Ma0OI",
      apiKey: 'AIzaSyBA3TgGXiwrL29sSyfR7e8TK3mLplTuXe4',
      libraries: ['places']
    }),
    PushNotificationsModule,
    DateInputsModule,
    NgMultiSelectDropDownModule.forRoot(),
    
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "en-GB" }],
  bootstrap: [AppComponent]
})
export class AppModule {}
